import logo from './160684.webp';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { useState, useEffect } from "react"
import { useAccount, configureChains, createConfig, WagmiConfig } from 'wagmi'
import { mainnet } from 'wagmi/chains'
import { Web3Button } from '@web3modal/react'
import { Alchemy, Network } from "alchemy-sdk";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

const config = {
  apiKey: "umbNXF_mN7XSRzuIC8RG2UX5JFksXC4E",
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(config);
const chains = [mainnet]
const projectId = '0bcf0aa44a2157b525fbcc341a8eac8a'
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)
//const nftAddressToCheck = ['0x495f947276749ce646f68ac8c248420045cb7b5e', '0x13defbac61a79761eb40b86e41b0fe3b9541aeff'];
const nftAddressToCheck = ['0x495f947276749ce646f68ac8c248420045cb7b5e'];

const nftData = [
  /*
  {
    name: "Wolf Den Wolf Pups",
    address: '0x13defbac61a79761eb40b86e41b0fe3b9541aeff',
    principal: 1000,
    apy: 50,
  },
  */
  {
    name: "Gold Genesis Vault",
    address: '0x495f947276749ce646f68ac8c248420045cb7b5e',
    principal: 4000,
    apy: 18.5,
  },
  {
    name: "Platinum Genesis Vault",
    address: '0x495f947276749ce646f68ac8c248420045cb7b5e',
    principal: 8000,
    apy: 20,
  },
  {
    name: "Diamond Genesis Vault",
    address: '0x495f947276749ce646f68ac8c248420045cb7b5e',
    principal: 16000,
    apy: 22,
  },
  {
    name: "Emerald Genesis Vault",
    address: '0x495f947276749ce646f68ac8c248420045cb7b5e',
    principal: 32000,
    apy: 23.5,
  },
  {
    name: "Pink Diamond Genesis Vault",
    address: '0x495f947276749ce646f68ac8c248420045cb7b5e',
    principal: 50000,
    apy: 25,
  },
  {
    name: "Blue Diamond Genesis Vault",
    address: '0x495f947276749ce646f68ac8c248420045cb7b5e',
    principal: 90000,
    apy: 26.5,
  },
  {
    name: "Legacy Genesis Vault",
    address: '0x495f947276749ce646f68ac8c248420045cb7b5e',
    principal: 150000,
    apy: 30.5,
  },
  {
    name: "The Legacy Genesis Vault - 1",
    address: '0x495f947276749ce646f68ac8c248420045cb7b5e',
    principal: 150000,
    apy: 30.5,
  }
];

const nftNamesToCheck = nftData.map(nft => nft.name);

const startDate = new Date(2022, 10, 1);

const nftChartData = generateChartData(nftData, startDate);

function getChartData(nftName) {
  const nft = nftData.find((n) => n.name === nftName);
  return nftChartData[nft.name] || null;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 2,
  color: '#eac35a',
  plugins: {
    legend: {
      display: false,
      position: 'top',
      labels: {
        color: '#eac35a'
      }
    },
    title: {
      display: true,
      text: '',
      color: '#eac35a'
    },
  },
  scales: {
    x: {
      ticks: {
        color: '#eac35a'
      }
    },
    y: {
      ticks: {
        color: '#eac35a'
      }
    }
  }
};

function calculateAccruedBalance(principal, apy, elapsedMonths) {
  const monthlyRate = (apy / 100) / 12;
  return principal * Math.pow(1 + monthlyRate, elapsedMonths);
}

function generateChartData(nftData, startDate) {
  const chartData = {};
  const currentDate = new Date();
  const monthsElapsed = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth());

  nftData.forEach((nft) => {
    const data = [nft.principal];

    for (let i = 1; i <= monthsElapsed; i++) {
      const elapsedMonths = Math.min(monthsElapsed, i); 
      const accruedBalance = calculateAccruedBalance(nft.principal, nft.apy, elapsedMonths); 
      data.push(accruedBalance.toFixed(2));
    }

    chartData[nft.name] = {
      labels: Array.from({ length: monthsElapsed + 1 }, (_, i) => {
        const labelDate = new Date(startDate.getFullYear(), startDate.getMonth() + i - 1, 1);
        return `${labelDate.toLocaleString('default', { month: 'long' })} ${labelDate.getFullYear()}`;
      }),
      datasets: [
        {
          label: 'Accrued Balance',
          data: data,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)'
        }
      ],
    };
  });

  return chartData;
}

// ... (imports and constants)

function App() {
  const [error] = useState();
  const [walletOwnsNFT, setWalletOwnsNFT] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [showTestButton, setShowTestButton] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState([]);
  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [isWhitelisted, setIsWhitelisted] = useState(false); 

  const whitelist = ['0x2a3A8B6190ccf6372a8CfEE1bbF1a5FE72116bd9'];

  async function getAndCheckNFTs(address) {
    //console.log('address:', address);
  
    // test 
    //address = '0x2a3A8B6190ccf6372a8CfEE1bbF1a5FE72116bd9';
    //address = '0xF1Fa30783F3c4af4233b4Dcad3B90799ACFdf998';
    //address = '0x23f2334f980d40c8db7b5836079705f3b8058f29';
    // end test
  
    if (whitelist.includes(address)) {
      setIsWhitelisted(true);
      const allNFTNames = nftData.map((nft) => nft.name);
      setWalletOwnsNFT(allNFTNames);
    } else if (address && !testMode) {
      try {
        const response = await alchemy.nft.getNftsForOwner(address, { contractAddresses: nftAddressToCheck });
        const ownedNfts = response.ownedNfts;
        const matchingNFTs = [];
        //console.log('ownedNfts:', ownedNfts);
  
        for (const nft of ownedNfts) {
          //console.log('nft', nft);
          if (nftAddressToCheck.includes(nft.contract.address)) {
            //console.log('match', nft);
            let nftTitle = nft.title || nft.name;
            if (nft.contract.name === "Wolf Den Wolf Pups") {
              matchingNFTs.push(nft.contract.name);
            } else {
              matchingNFTs.push(nftTitle);
            }
          }
        }
  
        //console.log('matchingNFTs:', matchingNFTs);
        if (matchingNFTs.length > 0) {
          setWalletOwnsNFT(matchingNFTs);
        } else {
          setWalletOwnsNFT([]);
        }
      } catch (error) {
        //console.error("Error fetching NFTs:", error);
        setWalletOwnsNFT([]);
      }
    } else {
      setWalletOwnsNFT(testMode ? selectedNFT : []);
      if (testMode) {
        setShowContent(true);
      } else {
        setShowContent(false);
      }
    }
  }


  function Dashboard({ walletOwnsNFT, showContent }) {
    const [chartData, setChartData] = useState([]);
    const [initialDeposit, setInitialDeposit] = useState([]);
    const [currentBalance, setCurrentBalance] = useState([]);

    useEffect(() => {
      const headerElement = document.querySelector('.App-header');
      if (showContent) {
        headerElement.classList.add('dashboard-content');
      } else {
        headerElement.classList.remove('dashboard-content');
      }
    }, [showContent]);

    useEffect(() => {
      if (walletOwnsNFT && walletOwnsNFT.length > 0) {
        //console.log('walletOwnsNFT', walletOwnsNFT);
        const data = [];
        const updatedInitialDeposit = [];
        const updatedCurrentBalance = [];
    
        walletOwnsNFT.forEach((nftName) => {
          //console.log('nftName', nftName);
          //console.log('nftData', nftData);
          const nft = nftData.find((n) => n.name === nftName);
          //console.log('nftfind', nft);
          if (nft) {
            let modifiedNFTName = nft.name;
            if (modifiedNFTName === 'The Legacy Genesis Vault - 1') {
              modifiedNFTName = "Legacy Genesis Vault";
              setWalletOwnsNFT(walletOwnsNFT.map((name) => (name === 'The Legacy Genesis Vault - 1' ? 'Legacy Genesis Vault' : name)));
            }
            const chartData = getChartData(modifiedNFTName);
            //console.log('modifiedNFTName', modifiedNFTName);
            //console.log('chartData', chartData);
            if (chartData) {
              data.push(chartData);
              const lastDataPoint = parseFloat(chartData.datasets[0].data.slice(-1)[0]);
              updatedInitialDeposit.push(nft.principal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
              updatedCurrentBalance.push(lastDataPoint.toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
            }
          }
        });
    
        setChartData(data);
        setInitialDeposit(updatedInitialDeposit);
        setCurrentBalance(updatedCurrentBalance);
      }
    }, [walletOwnsNFT]);

    if (showContent) {
      if (chartData && chartData.length > 0) {
        // Render information for each NFT
        return (
          <div className="dashboard-content">
            {chartData.map((data, index) => (
              <div key={index}>
                <h2>{walletOwnsNFT[index]} Investment Growth</h2>
                <div className="values-container">
                  <div className="initial-deposit">
                    <h3>Initial Deposit:</h3>
                    <p>{initialDeposit[index] || 'N/A'}</p>
                  </div>
                  <div className="current-balance">
                    <h3>Current Balance:</h3>
                    <p>{currentBalance[index] || 'N/A'}</p>
                  </div>
                </div>
                <div className="chart-wrapper">
                <Line
                  data={data}
                  options={chartOptions}
                  width=""
                  height=""                  
                />
                </div>
              </div>
            ))}
          </div>
        );
      }

      return <div>No NFTs owned</div>;
    }
  }

  function handleNFTSelection(selectedNames) {
    setSelectedNFT(selectedNames);
    setWalletOwnsNFT(selectedNames);
  }

  function handleToggleTestMode() {
    setTestMode(!testMode);
    if (!testMode) {
      setWalletOwnsNFT(selectedNFT);
      //console.log('set wallet owns to ' + selectedNFT + ' and show content true');
      //getAndCheckNFTs(testAddress);
      setShowContent(true);
    } else {
      setWalletOwnsNFT(null);
      //console.log('set wallet owns to null and show content false');
      setShowContent(false);
    }
  }

  function handleEnterDashboard() {
    setShowContent(true);
  }

  function HomePage({ getAndCheckNFTs, walletOwnsNFT, onEnterDashboard, testMode }) {
    const account = useAccount({
      onConnect({ address }) {
        if (walletAddress !== account.address) {
          setWalletAddress(account.address);
          setWalletConnected(true);
          //console.log('setWalletAddress Ethereum Account:', account.address);
          getAndCheckNFTs(account.address);
        }
      }
    });
    /*
      useEffect(() => {
        console.log('walletConnected', walletConnected);
        console.log('account.status', account.status);
        if (walletConnected) {
          if (account.status === 'disconnected') {
            console.log('disconnected');
            setWalletOwnsNFT([]);
            setShowContent(false);
            setWalletConnected(false);
          }
        }
      }, [account.status])
    */
    //console.log('walletOwnsNFT:', walletOwnsNFT);

    return (
      <>
        <Web3Button />
        {isWhitelisted || ((walletOwnsNFT && walletOwnsNFT.length > 0) || testMode) ? (
          <button className="enter_button" onClick={onEnterDashboard}>
            <div className="text">Enter Dashboard</div>
            <div className="icon">
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </button>
        ) : null}
        <br />
        {showTestButton && (
          <>
            <button onClick={handleToggleTestMode}>
              {testMode ? "Exit Test Mode" : "Enter Test Mode"}
            </button>
            {testMode && (
              <select
                multiple  // Enable multiple selections
                onChange={(e) => {
                  const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
                  handleNFTSelection(selectedOptions);
                }}
                value={selectedNFT}
              >
                {nftNamesToCheck.map((name) => (
                  <option key={name} value={name}>
                    Test {name}
                  </option>
                ))}
              </select>
            )}
          </>
        )}
      </>
    );
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const testParam = urlParams.get('test');
    if (testParam === 'true') {
      setShowTestButton(true);
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src={logo} className="App-logo" alt="Genesis Vault" />
          <h1 className="gv-hero__title font-title">The Genesis Vaults Collection</h1>
        </div>
        <br />

        <WagmiConfig config={wagmiConfig}>
          <HomePage
            getAndCheckNFTs={getAndCheckNFTs}
            walletOwnsNFT={walletOwnsNFT}
            onEnterDashboard={handleEnterDashboard}
            testMode={testMode}
            handleToggleTestMode={handleToggleTestMode}
          />
        </WagmiConfig>

        {(walletOwnsNFT || testMode) ? (
          <Dashboard walletOwnsNFT={walletOwnsNFT} showContent={showContent} />
        ) : null}

        <span className="text-xl text-white">{error}</span>
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      </header>
    </div>
  );
}

export default App;